import React from "react";
import PropTypes from "prop-types";

const BlogRow = ({ name, link }) => {
  return (
    <tr>
      <td className="py-4">
        <a className="underline" href={link}>
          {name}
        </a>
      </td>
    </tr>
  );
};

BlogRow.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
};

export default BlogRow;
