import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import BlogRow from "../components/blogRow";

function BlogPage() {
  return (
    <Layout>
      <SEO
        keywords={["Marcos", "Comelli", "Frontend", "Software", "Developer"]}
        title="Blog"
      />

      <section>
        <h2 className="inline-block p-3 mb-6 text-xl md:text-4xl font-bold bg-background-secondary">
          Blog 📖
        </h2>

        <div className="flex justify-center">
          <table className="w-full text-center">
            <tbody>
              <BlogRow
                name="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                link="#"
              />
              <BlogRow
                name="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                link="#"
              />
              <BlogRow
                name="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                link="#"
              />
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  );
}

export default BlogPage;
